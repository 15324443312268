const authURL = '/auth/counterparty'
const loginURL = '/auth/login'
export default defineNuxtRouteMiddleware((to, from) => {
  if (import.meta.browser) {
    const auth = useAuthState()
    const { counterpartyId } = useCounterpartyCookie()
    if (auth.status.value === 'authenticated' && [ undefined, null ].includes(counterpartyId.value) && to.fullPath !== authURL) {
      return navigateTo(authURL)
    }
    else if(auth.status.value !== 'authenticated' && to.fullPath !== loginURL){
      return navigateTo(loginURL)
    }
  }
})
