<template>
  <div class="flex h-screen items-center justify-center">
    <div class="flex flex-col text-center content-center items-center">
      <h1 class="text-9xl font-bold">{{ error?.statusCode }}</h1>
      <span class="text-3xl font-bold">{{ $t(error?.message) }}</span>
      <Button
        icon-pos="right"
        @click="handleError"
        icon="pi pi-arrow-right"
        :label="$t('Go to catalog')"
        class="mt-8 max-w-min whitespace-nowrap p-button-lg"
      />
    </div>
  </div>
</template>

<script setup>
  import Button from 'primevue/button'

  definePageMeta({
    layout: 'error'
  })
  const props = defineProps({
    error: Object
  })
  const error = useError()

  if (error.value.statusCode === 404) {
    error.value.message = 'Page not found'
  }
  const handleError = () => clearError({ redirect: '/' })
</script>
