import { createI18n } from 'vue-i18n'
import ruRU from '~/locales/ru-RU.json'

export default defineNuxtPlugin(({ vueApp }) => {
  const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: 'ruRU',
    messages: { ruRU }
  })
  vueApp.use(i18n)
})
