export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('digits', {
    mounted(el) {
      const digitsOnly = (evt: any) => {
        evt = (evt) ? evt : window.event
        const charCode = (evt.which) ? evt.which : evt.keyCode
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault()
        } else {
          return true
        }
      }
      function pasteTest(event) {
        window.setTimeout(() => {
          var characters = event.target.value
          window.setTimeout(() => {
            if (!(/^\d+$/.test(characters))) {
              event.target.value = event.target.value.replace(/\D/g, '')
            }
          })
        })
      }

      el.addEventListener('keypress', digitsOnly, false)
      el.addEventListener('paste', pasteTest, false)
    },

    getSSRProps(binding, vnode) {
      // you can provide SSR-specific props here
      return {}
    }
  })
})
