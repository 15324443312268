import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import counterparty_45global from "/app/middleware/counterparty.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  counterparty_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/app/node_modules/@qummy-rnd/nuxt-auth/dist/runtime/middleware/auth.mjs")
}