export const useCounterpartyCookie = () => {
  const cookieCounterpartyName = 'counterparty_id'
  const cookieCounterpartyContractName = 'contract_id'
  const counterpartyId = useCookie<string | null>(cookieCounterpartyName, { default: () => null })
  const counterpartyContractId = useCookie<string | null>(cookieCounterpartyContractName, { default: () => null })
  const queryParameters = computed(() => ({
    [cookieCounterpartyName]: counterpartyId.value,
    [cookieCounterpartyContractName]: counterpartyContractId.value,
  }))
  return { counterpartyId, counterpartyContractId, queryParameters }
}
